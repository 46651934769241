export const initilaFilterData: Record<
  string,
  { label: string; count: number }
> = {
  ALL: { label: 'ALL', count: 0 },
  'MY POOLS': { label: 'MY POOLS', count: 0 },
  'RP POOLS': { label: 'RP POOLS', count: 0 },
  LABS: { label: 'LABS', count: 0 },
  SHARED: { label: 'SHARED', count: 1 },
  L1L2: { label: 'L1 / L2', count: 0 },
  MEMES: { label: 'MEMES', count: 0 },
  AMM: { label: 'AMM', count: 0 },
  DEFI: { label: 'DEFI', count: 0 },
}
