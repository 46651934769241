import { IS_VERBOSE } from 'config/development'
import {
  ARBITRUM,
  ARBITRUM_GOERLI,
  ARBITRUM_SEPOLIA,
  AVALANCHE,
  AVALANCHE_FUJI,
  ETH_MAINNET,
  ZKSYNC,
  ZKSYNC_SEPOLIA,
} from './chains'
import { isDevelopment } from './env'
import { getSubgraphUrlKey } from './localStorage'

const SUBGRAPH_URLS: any = {
  [ARBITRUM]: {
    stats:
      'https://subgraph.satsuma-prod.com/3b2ced13c8d9/gmx/gmx-arbitrum-stats/api',
    referrals:
      'https://subgraph.satsuma-prod.com/3b2ced13c8d9/gmx/gmx-arbitrum-referrals/api',
    nissohVault: 'https://api.thegraph.com/subgraphs/name/nissoh/gmx-vault',
    syntheticsStats:
      'https://subgraph.satsuma-prod.com/3b2ced13c8d9/gmx/synthetics-arbitrum-stats/api',
  },

  [ARBITRUM_GOERLI]: {
    stats:
      'https://subgraph.satsuma-prod.com/3b2ced13c8d9/gmx/gmx-arbitrum-stats/api',
    referrals:
      'https://subgraph.satsuma-prod.com/3b2ced13c8d9/gmx/gmx-goerli-referrals/api',
    syntheticsStats:
      'https://subgraph.satsuma-prod.com/3b2ced13c8d9/gmx/synthetics-goerli-stats/version/incentives3-231114103805-25eb066/api',
  },

  [AVALANCHE]: {
    stats:
      'https://subgraph.satsuma-prod.com/3b2ced13c8d9/gmx/gmx-avalanche-stats/api',
    referrals:
      'https://subgraph.satsuma-prod.com/3b2ced13c8d9/gmx/gmx-avalanche-referrals/api',
    syntheticsStats:
      'https://subgraph.satsuma-prod.com/3b2ced13c8d9/gmx/synthetics-avalanche-stats/version/incentives3-231114103203-25eb066/api',
  },

  [AVALANCHE_FUJI]: {
    stats: 'https://api.thegraph.com/subgraphs/name/gmx-io/gmx-avalanche-stats',
    referrals:
      'https://subgraph.satsuma-prod.com/3b2ced13c8d9/gmx/gmx-fuji-referrals/api',
    syntheticsStats:
      'https://subgraph.satsuma-prod.com/3b2ced13c8d9/gmx/synthetics-fuji-stats/version/incentives3-231114103238-25eb066/api',
  },

  [ARBITRUM_SEPOLIA]: {
    stats:
      'https://subgraph.satsuma-prod.com/02eae233836e/relative-finance/synthetics-sepolia-stats-pyth/api',
    referrals:
      'https://subgraph.satsuma-prod.com/02eae233836e/relative-finance/synthetics-sepolia-stats-pyth/api',
    syntheticsStats:
      'https://subgraph.satsuma-prod.com/02eae233836e/relative-finance/synthetics-sepolia-stats-pyth/api',
  },

  [ZKSYNC_SEPOLIA]: {
    stats: 'https://api.studio.thegraph.com/query/62681/rfx-2-1/version/latest',
    referrals:
      'https://api.studio.thegraph.com/query/62681/rfx-referrals/version/latest',
    syntheticsStats:
      'https://api.studio.thegraph.com/query/62681/rfx-2-1/version/latest',
    leaderboard:
      'https://api.studio.thegraph.com/query/62681/rfxl-2-1/version/latest',
  },
  [ZKSYNC]: {
    stats:
      'https://api.studio.thegraph.com/query/62681/rfxs-master/version/latest',
    referrals:
      'https://api.studio.thegraph.com/query/62681/rfxr-master/version/latest',
    syntheticsStats:
      'https://api.studio.thegraph.com/query/62681/rfxs-master/version/latest',
    leaderboard:
      'https://api.studio.thegraph.com/query/62681/rfxl-master/version/latest',
  },

  common: {
    [ETH_MAINNET]: {
      chainLink: 'https://api.thegraph.com/subgraphs/name/deividask/chainlink',
    },
  },
}

export function getSubgraphUrl(chainId: number, subgraph: string) {
  if (isDevelopment()) {
    const localStorageKey = getSubgraphUrlKey(chainId, subgraph)
    const url = localStorage.getItem(localStorageKey)
    if (url) {
      IS_VERBOSE &&
        // eslint-disable-next-line no-console
        console.warn(
          '%s subgraph on chain %s url is overriden: %s',
          subgraph,
          chainId,
          url,
        )
      return url
    }
  }

  return SUBGRAPH_URLS?.[chainId]?.[subgraph]
}
